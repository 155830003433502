<template>
    
    <div class="box h-full overflow-hidden flex flex-col relative">
        <loader :loading="loading && loading2" />
        <div class="flex items-center">
            <div class="box-title">ANSR</div>
            <i v-if=" ner.objective_percentage > 99" class="mdi mdi-arrow-top-right text-success text-xl ml-auto"></i>
            <i v-else class="mdi mdi-arrow-bottom-left text-danger text-xl ml-auto"></i>
        </div>
        <div class="h-72 mb-auto overflow-hidden -mt-5">
            <chart-gauge :data="[ner.objective_percentage]">
                <div class="text-xl font-bold">{{ ner.ner || 0 | reduceBigNumbers(2) }} €</div>
                <div class="text-font-gray text-sm mt-1">% Obj</div>
                <div class="text-xl font-bold"
                     :class="successOrDangerClass(ner.objective_percentage,100)"
                >
                    <span v-if="ner.objective_percentage > 0">+</span>{{ ner.objective_percentage || 0 | numberFormat(2) }}<span v-if="ner.objective_percentage!='N/A'&&ner.objective_percentage!='TBD'">%</span>
                </div>
            </chart-gauge>
        </div>
        <div>
            <div class="flex flex-row justify-start items-center">
                <div class="text-font-dark mr-auto">vsForecast (FYTD)</div>
                <span :class="{'text-success': ner_y1.total >= 0, 'text-danger': ner_y1.total < 0}"><span class="font-bold text-success" v-if="ner_y1.total>0">+</span>{{ ner_y1.total || 0 | reduceBigNumbers(0) }}€</span>
                <!-- <div class="font-bold text-success ml-auto w-30 flex flex-row justify-end items-center">
                </div> -->
            </div>
            <div class="h-22 overflow-auto">
                <stacked-chart v-if="ner_y1" :data="[ner_y1.data.ner,ner_y1.data.ner_forecast]" :chartId="'stackedChart'" />
            </div>
        </div>
    </div>

</template>

<script>

import { state } from '@/store';
const StackedChart = () => import('@/charts/StackedChart');

export default {
    components:{
        StackedChart
    },
    data() {
        return {
            loading: false,
            loading2: false,
            ner: false,
            ner_y1: false
        }
    },
    methods: {
        load() {

            this.loading = true
            this.loading2 = true

            // if(state.rolSelected.role_type_link != 'presidencia'){
            
                this.axios.get('projects_management/ner', { params: this.params })
                .then(response => {
                    this.ner = response.data.data
                })
                this.axios.get('projects_management/ner_y1', { params: this.params })
                .then(response => {
                    this.ner_y1 = response.data
                })

            // }

            this.loading = false
            this.loading2 = false


        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        }
    },
    watch: {
        params() { this.load() }
    },
    mounted() { this.load() }
}
</script>